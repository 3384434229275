import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import PropTypes from 'prop-types';
import { useLocation, Link, useSearchParams } from 'react-router-dom';
//import SidebarData from '../sidebars/sidebardata/SidebarData';

const BreadCrumbs = ({ title }) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const firstUrl = location.pathname.split('/')[1];
  const secondUrl = location.pathname.split('/')[2];
  const embeddedMode = searchParams.get('embedded_mode');

  return (
    !embeddedMode && (
      <>
        <h4 className="text-capitalize">{title || (secondUrl ? `${secondUrl}` : `${firstUrl}`)}</h4>
        <Breadcrumb>
          <BreadcrumbItem to="/" tag={Link} className="text-decoration-none">
            Home
          </BreadcrumbItem>
          {firstUrl ? <BreadcrumbItem active>{firstUrl}</BreadcrumbItem> : ''}
          {secondUrl ? <BreadcrumbItem active>{secondUrl}</BreadcrumbItem> : ''}
        </Breadcrumb>
      </>
    )
  );
};

BreadCrumbs.propTypes = {
  title: PropTypes.string,
};

export default BreadCrumbs;
