import React, { Suspense, useContext } from 'react';
import { useRoutes, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Security } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import Themeroutes from './routes/Router';
import ThemeSelector from './layouts/theme/ThemeSelector';
import Loader from './layouts/loader/Loader';
import AuthRequiredModal from './components/authGuard/AuthRequiredModal';
import CorsErrorModal from './components/authGuard/CorsErrorModal';
import { AuthContext } from './contexts/auth';
import { authConfig } from './globals';

const App = () => {
  const routing = useRoutes(Themeroutes);
  const direction = useSelector((state) => state.customizer.isRTL);
  const isMode = useSelector((state) => state.customizer.isDark);
  const [corsErrorModalOpen, setCorsErrorModalOpen] = React.useState(false);
  const [authRequiredModalOpen, setAuthRequiredModalOpen] = React.useState(false);
  const navigate = useNavigate();
  const oktaAuth = useContext(AuthContext);

  const triggerLogin = async () => {
    await oktaAuth.signInWithRedirect();
  };

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    // eslint-disable-next-line
    // const { idToken } = _oktaAuth.authStateManager._authState.idToken;
    // await dispatch(fetchPermissions(idToken));
    navigate(toRelativeUrl(originalUri || '/', window.location.origin), { replace: true });
  };

  const customAuthHandler = async () => {
    const previousAuthState = oktaAuth.authStateManager.getPreviousAuthState();

    if (previousAuthState?.isAuthenticated) {
      // Ask the user to trigger the login process during token autoRenew process
      setAuthRequiredModalOpen(true);
    } else {
      // App initialization stage
      await triggerLogin();
    }
  };

  return (
    <Suspense fallback={<Loader />}>
      <div
        data-testid="app-container"
        className={`${direction ? 'rtl' : 'ltr'} ${isMode ? 'dark' : ''}`}
        dir={direction ? 'rtl' : 'ltr'}
      >
        <ThemeSelector />
        <Security
          oktaAuth={oktaAuth}
          onAuthRequired={customAuthHandler}
          restoreOriginalUri={restoreOriginalUri}
        >
          <CorsErrorModal
            issuer={authConfig.issuer}
            {...{ corsErrorModalOpen, setCorsErrorModalOpen }}
          />
          <AuthRequiredModal
            {...{ authRequiredModalOpen, setAuthRequiredModalOpen, triggerLogin }}
          />
          {routing}
        </Security>
      </div>
    </Suspense>
  );
};

export default App;
